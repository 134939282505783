"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Interface = void 0;
const pixi_filters_1 = require("pixi-filters");
const pixi_js_1 = require("pixi.js");
const game_1 = require("../game");
const LOGO_WIDTH = 720;
const LOGO_HEIGHT = 92;
const SPIN_BUTTON_WIDTH = 166;
const SPIN_BUTTON_HEIGHT = 47;
class Interface extends pixi_js_1.Container {
    constructor(mainContainer, slotContainer, controls) {
        super();
        this.mainContainer = mainContainer;
        this.slotContainer = slotContainer;
        this.controls = controls;
        this.addbBackground();
        this.addLogo();
        this.addSpinButton();
        this.mainContainer.addChild(this);
    }
    addLogo() {
        const texture = pixi_js_1.Texture.from("logo");
        const sprite = new pixi_js_1.Sprite(texture);
        sprite.height = LOGO_HEIGHT;
        sprite.width = LOGO_WIDTH;
        sprite.x = LOGO_WIDTH / 2 + game_1.SLOT_MARGIN_LEFT_RIGHT / 2;
        sprite.y = LOGO_HEIGHT / 2;
        sprite.anchor.set(0.5);
        this.addChild(sprite);
    }
    addbBackground() {
        const texture = pixi_js_1.Texture.from("bg");
        const sprite = new pixi_js_1.Sprite(texture);
        sprite.height = game_1.SLOT_MARGIN_TOP_BOTTOM + game_1.SLOT_HEIGHT;
        sprite.width = game_1.SLOT_MARGIN_LEFT_RIGHT + game_1.SLOT_WIDTH;
        sprite.x = 0;
        sprite.y = 0;
        this.addChild(sprite);
    }
    addSpinButton(pressed = false) {
        const btn = pressed
            ? pixi_js_1.Texture.from("button-pressed")
            : pixi_js_1.Texture.from("button");
        this.spinButton = new pixi_js_1.Sprite(btn);
        this.spinButton.height = SPIN_BUTTON_HEIGHT;
        this.spinButton.width = SPIN_BUTTON_WIDTH;
        this.spinButton.x =
            game_1.SLOT_WIDTH - SPIN_BUTTON_WIDTH / 2 + game_1.SLOT_MARGIN_LEFT_RIGHT / 2 - 4;
        this.spinButton.y =
            game_1.SLOT_HEIGHT +
                SPIN_BUTTON_HEIGHT / 2 +
                game_1.SLOT_MARGIN_TOP_BOTTOM / 2 +
                game_1.SLOT_MARGIN_TOP_BOTTOM / 8;
        this.spinButton.anchor.set(0.5);
        this.spinButton.interactive = true;
        this.spinButton.buttonMode = true;
        const onClick = () => {
            this.controls.spin();
        };
        this.spinButton.on("mousedown", onClick);
        this.spinButton.on("touchstart", onClick);
        this.addChild(this.spinButton);
    }
    setCredits(amount) {
        if (this.credits) {
            this.removeChild(this.credits);
        }
        this.credits = new pixi_js_1.Text(`Credits: ${amount}`, {
            fontFamily: "Arial",
            fontSize: 34,
            fill: 0xffffff,
            align: "center",
        });
        this.credits.x = game_1.SLOT_MARGIN_LEFT_RIGHT / 2;
        this.credits.y =
            game_1.SLOT_MARGIN_TOP_BOTTOM / 2 + game_1.SLOT_HEIGHT + game_1.SLOT_MARGIN_TOP_BOTTOM / 8;
        this.addChild(this.credits);
    }
    onSpinStart() {
        if (this.winAmount) {
            this.slotContainer.removeChild(this.winAmount);
        }
        if (this.noCredits) {
            this.slotContainer.removeChild(this.noCredits);
        }
        this.addSpinButton(true);
    }
    onSpinDone() {
        this.addSpinButton(false);
    }
    showWinAmount(amount) {
        this.winAmount = new pixi_js_1.Text(`You have won\n${amount} credits!`, {
            fontFamily: "Arial",
            fontSize: 54,
            fill: 0xffffff,
            align: "center",
        });
        this.winAmount.filters = [
            new pixi_filters_1.GlowFilter({
                distance: 10,
                outerStrength: 1.5,
                innerStrength: 0.5,
                color: 0xffffff,
                quality: 1,
            }),
            new pixi_filters_1.DropShadowFilter({
                color: 0x000000,
                alpha: 1,
                blur: 5,
                distance: 0,
            }),
        ];
        this.winAmount.anchor.set(0.5);
        this.winAmount.x = game_1.SLOT_WIDTH / 2;
        this.winAmount.y = game_1.SLOT_HEIGHT / 2;
        this.slotContainer.addChild(this.winAmount);
    }
    showNoCreditsMessage() {
        this.noCredits = new pixi_js_1.Text(`Game over!`, {
            fontFamily: "Arial",
            fontSize: 70,
            fill: 0xffffff,
            align: "center",
        });
        this.noCredits.filters = [
            new pixi_filters_1.DropShadowFilter({
                color: 0x000000,
                alpha: 1,
                blur: 5,
                distance: 0,
            }),
            new pixi_filters_1.DropShadowFilter({
                color: 0x000000,
                alpha: 1,
                blur: 15,
                distance: 0,
            }),
        ];
        this.noCredits.anchor.set(0.5);
        this.noCredits.x = game_1.SLOT_WIDTH / 2;
        this.noCredits.y = game_1.SLOT_HEIGHT / 2;
        this.slotContainer.addChild(this.noCredits);
    }
}
exports.Interface = Interface;
