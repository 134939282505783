"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WinLines = void 0;
const pixi_js_1 = require("pixi.js");
const game_1 = require("../game");
const pixi_filters_1 = require("pixi-filters");
class WinLines extends pixi_js_1.Container {
    constructor(container) {
        super();
        this.container = container;
        this.x = 0; // SLOT_MARGIN_LEFT_RIGHT / 2;
        this.y = 0; // SLOT_MARGIN_TOP_BOTTOM / 2;
        this.container.addChild(this);
    }
    drawWinLines(winLines) {
        this.clear();
        winLines.forEach((line) => {
            const points = [];
            // left to first
            points.push(new pixi_js_1.Point(0, line[0] * game_1.REEL_ICON_HEIGHT + game_1.REEL_ICON_HEIGHT / 2));
            // lines in between
            line.map((rowNr, index) => {
                points.push(new pixi_js_1.Point(index * game_1.REEL_ICON_WIDTH + game_1.REEL_ICON_WIDTH / 2, rowNr * game_1.REEL_ICON_HEIGHT + game_1.REEL_ICON_HEIGHT / 2));
            });
            // last to right
            points.push(new pixi_js_1.Point(game_1.SLOT_WIDTH, line[line.length - 1] * game_1.REEL_ICON_HEIGHT + game_1.REEL_ICON_HEIGHT / 2));
            this.drawLine(points);
        });
    }
    drawLine(points) {
        const line = new pixi_js_1.Graphics();
        line.lineStyle(5, 0xf616f3);
        for (let index = 0; index < points.length; index++) {
            const p = points[index];
            if (index === 0) {
                line.moveTo(p.x, p.y);
            }
            else {
                line.lineTo(p.x, p.y);
            }
        }
        line.filters = [
            new pixi_filters_1.GlowFilter({
                distance: 10,
                outerStrength: 1.5,
                innerStrength: 0.5,
                color: 0xffffff,
                quality: 1,
            }),
        ];
        this.addChild(line);
    }
    clear() {
        this.removeChildren();
    }
}
exports.WinLines = WinLines;
