"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.rotateMatrixRight = exports.rotateMatrixLeft = exports.timeout = exports.fakeApi = exports.getRandomInt = void 0;
const game_1 = require("./game");
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}
exports.getRandomInt = getRandomInt;
let chance = [1, 2, 3, 4, 5, 6, 7];
exports.fakeApi = {
    call: (payAmount) => __awaiter(void 0, void 0, void 0, function* () {
        const matrix = new Array(game_1.SLOT_REEL_COUNT).fill(0).map(() => {
            return new Array(game_1.SLOT_ICONS_PER_REEL_COUNT).fill(0).map(() => {
                const rand = chance[Math.floor(Math.random() * chance.length)];
                return rand;
            });
        });
        const lineMatches = {
            "2x2": [
                [0, 0],
                [1, 1],
                [2, 2],
            ],
            "3x1": [[0, 0, 0]],
            "3x3": [
                [0, 0, 0],
                [1, 1, 1],
                [2, 2, 2],
            ],
            "3x4": [
                [0, 0, 0],
                [1, 1, 1],
                [2, 2, 2],
                [3, 3, 3],
            ],
            "5x3": [
                [0, 0, 0, 0, 0],
                [0, 0, 1, 2, 2],
                [1, 1, 1, 1, 1],
                [2, 2, 1, 0, 0],
                [2, 2, 2, 2, 2],
                [0, 1, 2, 1, 0],
                [2, 1, 0, 1, 2],
            ],
            // todo: add more
        };
        if (!lineMatches[`${game_1.SLOT_REEL_COUNT}x${game_1.SLOT_ICONS_PER_REEL_COUNT}`]) {
            return Promise.reject(`No winning lines found for ${game_1.SLOT_REEL_COUNT}x${game_1.SLOT_ICONS_PER_REEL_COUNT}`);
        }
        // winning lines for 3x5
        const lines = lineMatches[`${game_1.SLOT_REEL_COUNT}x${game_1.SLOT_ICONS_PER_REEL_COUNT}`];
        console.log("chances on", chance.join(", "));
        // find winning lines
        const winLines = [];
        const rotatedMatrix = rotateMatrixLeft(matrix);
        for (let index = 0; index < lines.length; index++) {
            const line = lines[index];
            const firstIcon = rotatedMatrix[line[0]][0];
            const isWin = line.every((nr, index) => {
                return rotatedMatrix[nr][index] === firstIcon;
            });
            if (isWin) {
                winLines.push(line);
            }
        }
        // hihger chance with each spin on 7
        if (winLines.length) {
            chance = new Array(game_1.SLOT_ICON_COUNT).fill(0).map((n, i) => i + 1);
        }
        else {
            chance.push(7);
        }
        // fake request delay
        yield timeout(100);
        return {
            matrix,
            win: !!winLines.length,
            winLines,
            winAmount: winLines.reduce((cur, line) => {
                const firstIcon = rotatedMatrix[line[0]][0];
                return Math.round(cur + firstIcon * payAmount * 0.75);
            }, 0),
        };
    }),
};
function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
exports.timeout = timeout;
function rotateMatrixLeft(matrix) {
    return matrix[0]
        .map((val, index) => matrix.map((row) => row[index]))
        .reverse();
}
exports.rotateMatrixLeft = rotateMatrixLeft;
function rotateMatrixRight(matrix) {
    return matrix[0].map((val, index) => matrix.map((row) => row[index]).reverse());
}
exports.rotateMatrixRight = rotateMatrixRight;
