"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Game = exports.REEL_WIDTH = exports.REEL_ICON_WIDTH = exports.REEL_ICON_HEIGHT = exports.SLOT_ICON_COUNT = exports.SLOT_ICONS_PER_REEL_COUNT = exports.SLOT_REEL_COUNT = exports.SLOT_SPIN_SPEED = exports.SLOT_ROLL_DELAY_VARIANCE = exports.SLOT_ROLL_DELAY = exports.SLOT_ROLL_TIME = exports.SLOT_MARGIN_LEFT_RIGHT = exports.SLOT_MARGIN_TOP_BOTTOM = exports.SLOT_HEIGHT = exports.SLOT_WIDTH = void 0;
const pixi_js_1 = require("pixi.js");
const slot_1 = require("./container/slot");
const assets_1 = require("./loader/assets");
exports.SLOT_WIDTH = 720;
exports.SLOT_HEIGHT = 300;
exports.SLOT_MARGIN_TOP_BOTTOM = 200;
exports.SLOT_MARGIN_LEFT_RIGHT = 100;
exports.SLOT_ROLL_TIME = 1000;
exports.SLOT_ROLL_DELAY = 300;
exports.SLOT_ROLL_DELAY_VARIANCE = 200;
exports.SLOT_SPIN_SPEED = 40;
exports.SLOT_REEL_COUNT = 5;
exports.SLOT_ICONS_PER_REEL_COUNT = 3;
exports.SLOT_ICON_COUNT = 7;
exports.REEL_ICON_HEIGHT = exports.SLOT_HEIGHT / exports.SLOT_ICONS_PER_REEL_COUNT;
exports.REEL_ICON_WIDTH = exports.SLOT_WIDTH / exports.SLOT_REEL_COUNT;
exports.REEL_WIDTH = exports.SLOT_WIDTH / exports.SLOT_REEL_COUNT;
class Game {
    constructor(onLoaded) {
        this.onLoaded = onLoaded;
        this.app = new pixi_js_1.Application({
            width: exports.SLOT_WIDTH + exports.SLOT_MARGIN_LEFT_RIGHT,
            height: exports.SLOT_HEIGHT + exports.SLOT_MARGIN_TOP_BOTTOM,
            preserveDrawingBuffer: true,
        });
        this.assets = new assets_1.Assets();
        this.assets.onComplete.add(() => {
            this.init();
            this.onLoaded();
        });
    }
    init() {
        this.keybinds();
        this.slot = new slot_1.Slot(this.app.stage);
        document.body.appendChild(this.app.view);
        // ? for dev only
        this.app.renderer.plugins.interaction.on("pointerup", (e) => {
            const x = e.data.global.x;
            const y = e.data.global.y;
            console.log("Click Postion:", x, y);
        });
    }
    spin() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.slot) {
                yield this.slot.spin();
            }
        });
    }
    keybinds() {
        document.addEventListener("keydown", (event) => {
            if (event.keyCode == 32) {
                this.spin();
                event.preventDefault();
            }
        });
    }
}
exports.Game = Game;
