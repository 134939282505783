"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reel = void 0;
const pixi_js_1 = require("pixi.js");
const helper_1 = require("../helper");
const game_1 = require("../game");
const sounds_1 = require("../loader/sounds");
class Reel extends pixi_js_1.Container {
    constructor(reelIndex) {
        super();
        this.reelIndex = reelIndex;
        this.icons = [];
        this.isSpinning = false;
        this.ticker = new pixi_js_1.Ticker();
        this.finalIcons = [];
        // first init reels
        this.prepareNextSpin(new Array(game_1.SLOT_ICONS_PER_REEL_COUNT)
            .fill(0)
            .map(() => (0, helper_1.getRandomInt)(1, game_1.SLOT_ICON_COUNT + 1)));
    }
    addIcon(iconName, startPosition = 0) {
        const texture = pixi_js_1.Texture.from(iconName + "");
        const icon = new pixi_js_1.Sprite(texture);
        icon.height = game_1.REEL_ICON_HEIGHT;
        icon.width = game_1.REEL_ICON_WIDTH;
        icon.x = 0;
        icon.y = startPosition * game_1.REEL_ICON_HEIGHT;
        this.addChild(icon);
        this.icons.unshift(icon);
        return icon;
    }
    spin([...finalIcons]) {
        this.finalIcons = finalIcons;
        if (this.isSpinning) {
            return Promise.reject("Reel is already spinning");
        }
        if (finalIcons.length != game_1.SLOT_ICONS_PER_REEL_COUNT) {
            return Promise.reject("Wrong spin input length");
        }
        return new Promise((resolve, reject) => {
            this.isSpinning = true;
            this.icons = [];
            // spin speed based on settings
            const MAX_ICONS_PER_SECOND = (60 * game_1.SLOT_SPIN_SPEED) / game_1.REEL_ICON_HEIGHT - 3;
            const MAX_ICONS_PER_ROLL_TIME = Math.floor((MAX_ICONS_PER_SECOND * game_1.SLOT_ROLL_TIME) / 1000);
            const DELAY_SPINS = Math.floor((MAX_ICONS_PER_SECOND * game_1.SLOT_ROLL_DELAY * this.reelIndex) / 1000);
            const VARIANCE_SPINS = Math.floor((MAX_ICONS_PER_SECOND * (0, helper_1.getRandomInt)(1, game_1.SLOT_ROLL_DELAY_VARIANCE)) /
                1000);
            // prepare spin icons
            const preSetReel = new Array(MAX_ICONS_PER_ROLL_TIME + DELAY_SPINS + VARIANCE_SPINS)
                .fill(0)
                .map(() => {
                return (0, helper_1.getRandomInt)(1, game_1.SLOT_ICON_COUNT + 1);
            });
            finalIcons.map((f) => preSetReel.push(f));
            preSetReel.map((f, i) => this.addIcon(f, -i - 1));
            // calc time with settings & icons
            const REEL_HEIGHT = preSetReel.length * game_1.REEL_ICON_HEIGHT;
            const REEL_SPEED = game_1.SLOT_SPIN_SPEED;
            const START_TIME = Date.now();
            let THROLLE_SPEED = REEL_SPEED;
            this.animation = () => __awaiter(this, void 0, void 0, function* () {
                if (this.y + REEL_SPEED > REEL_HEIGHT) {
                    // throttle last spin speed
                    if (THROLLE_SPEED < 2) {
                        THROLLE_SPEED = 1;
                    }
                    else {
                        THROLLE_SPEED = Math.floor(THROLLE_SPEED / 2);
                    }
                    this.y += THROLLE_SPEED;
                }
                else {
                    this.y += REEL_SPEED;
                }
                if (this.y > preSetReel.length * game_1.REEL_ICON_HEIGHT) {
                    this.ticker.remove(this.animation);
                    yield this.bounceReel();
                    console.log(`Reelspin ${this.reelIndex} done. Reel took ${Date.now() - START_TIME}ms`);
                    resolve(finalIcons);
                }
            });
            this.ticker.add(this.animation);
            this.ticker.start();
        });
    }
    bounceReel() {
        return new Promise((resolve, reject) => {
            let BOUNCE_SPEED = 8;
            sounds_1.sounds.reelSpinEnd();
            // todo: adapt bounce animation to spin speed and icon height
            this.y = this.height - game_1.SLOT_HEIGHT + 20;
            this.bounceAnmation = () => __awaiter(this, void 0, void 0, function* () {
                if (this.y > this.height - game_1.SLOT_HEIGHT) {
                    this.y -= BOUNCE_SPEED;
                    BOUNCE_SPEED = Math.floor(BOUNCE_SPEED / 2);
                    if (BOUNCE_SPEED < 1) {
                        BOUNCE_SPEED = 1;
                    }
                }
                else {
                    this.ticker.remove(this.bounceAnmation);
                    yield this.prepareNextSpin(this.finalIcons);
                    resolve();
                }
            });
            this.ticker.add(this.bounceAnmation);
        });
    }
    prepareNextSpin(finalIcons) {
        return new Promise((resolve, reject) => {
            if (finalIcons.length != game_1.SLOT_ICONS_PER_REEL_COUNT) {
                throw new Error("finalIcons.length != SLOT_ICONS_PER_REEL_COUNT");
            }
            this.icons = [];
            this.removeChildren();
            this.y = 0;
            for (let i = 0; i < game_1.SLOT_ICONS_PER_REEL_COUNT; i++) {
                this.addIcon(finalIcons.reverse()[i].toString(), i);
            }
            this.isSpinning = false;
            resolve();
        });
    }
}
exports.Reel = Reel;
