"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sounds = void 0;
const howler_1 = require("howler");
// Setup the new Howl.
exports.sounds = {
    theme: () => {
        new howler_1.Howl({
            src: ["sounds/theme.mp3"],
            volume: 0.2,
            loop: true,
        }).play();
    },
    win: () => {
        new howler_1.Howl({
            src: ["sounds/win.mp3"],
            volume: 0.5,
        }).play();
    },
    reelSpinEnd: () => {
        new howler_1.Howl({
            src: ["sounds/reel-spin-end.mp3"],
            volume: 1,
        }).play();
    },
    gameover: () => {
        new howler_1.Howl({
            src: ["sounds/gameover.mp3"],
            volume: 0.7,
        }).play();
    },
};
