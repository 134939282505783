"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slot = void 0;
const pixi_js_1 = require("pixi.js");
const game_1 = require("../game");
const helper_1 = require("../helper");
const reel_1 = require("./reel");
const interface_1 = require("./interface");
const win_lines_1 = require("./win-lines");
const sounds_1 = require("../loader/sounds");
class Slot extends pixi_js_1.Container {
    constructor(container) {
        super();
        this.container = container;
        this.isSpinning = false;
        this.credits = 1000;
        this.creditInput = 100;
        this.autoSpin = false;
        sounds_1.sounds.theme();
        this.x = game_1.SLOT_MARGIN_LEFT_RIGHT / 2;
        this.y = game_1.SLOT_MARGIN_TOP_BOTTOM / 2;
        this.reels = [];
        for (let i = 0; i < game_1.SLOT_REEL_COUNT; i++) {
            this.addReel(i);
        }
        this.winLines = new win_lines_1.WinLines(this);
        // todo: use event emitter for interface communication
        this.interface = new interface_1.Interface(container, this, {
            spin: this.spin.bind(this),
        });
        this.interface.setCredits(this.credits);
        this.setSlotMask();
        this.container.addChild(this);
    }
    addReel(i) {
        // add bg
        const texture = pixi_js_1.Texture.from("reel");
        const sprite = new pixi_js_1.Sprite(texture);
        sprite.height = game_1.REEL_WIDTH * game_1.SLOT_ICONS_PER_REEL_COUNT;
        sprite.width = game_1.REEL_WIDTH - 8;
        sprite.x = i * game_1.REEL_WIDTH + 4;
        sprite.y = 0;
        this.addChild(sprite);
        // add reel
        const reel = new reel_1.Reel(i);
        reel.x = i * game_1.REEL_WIDTH;
        reel.y = 0;
        reel.width = game_1.REEL_WIDTH;
        this.reels.push(reel);
        this.addChild(reel);
    }
    setSlotMask() {
        const graphics = new pixi_js_1.Graphics();
        graphics.beginFill(0xff3300);
        graphics.drawRect(game_1.SLOT_MARGIN_LEFT_RIGHT / 2, game_1.SLOT_MARGIN_TOP_BOTTOM / 2, game_1.SLOT_WIDTH, game_1.SLOT_HEIGHT);
        graphics.endFill();
        this.mask = graphics;
    }
    spin() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isSpinning) {
                return Promise.reject("Slot is already spinning");
            }
            // reset credits if empty
            if (this.credits === 0) {
                this.creditInput = 100;
                yield this.receiveCredits(1000);
            }
            this.winLines.clear();
            const payAmount = yield this.payCredits(this.creditInput);
            this.isSpinning = true;
            this.interface.onSpinStart();
            // fake api call
            const spins = [];
            const { matrix, win, winAmount, winLines } = yield helper_1.fakeApi.call(payAmount);
            // spin all reels
            this.reels.map((reel, i) => {
                spins.push(reel.spin(matrix[i]));
            });
            yield Promise.all(spins);
            // show win and get credits
            if (win) {
                this.winLines.drawWinLines(winLines);
                this.receiveCredits(winAmount);
                this.interface.showWinAmount(winAmount);
                sounds_1.sounds.win();
                console.log(`You have won: ${winAmount} credits`);
            }
            console.log(`Slot spin done. Money left: ${this.credits}`);
            this.isSpinning = false;
            this.interface.onSpinDone();
            if (this.credits < this.creditInput) {
                this.creditInput = this.credits;
            }
            if (this.credits === 0) {
                this.interface.showNoCreditsMessage();
                sounds_1.sounds.gameover();
            }
            // todo: add auto spin button
            // if (this.autoSpin) {
            //   setTimeout(
            //     () => {
            //       this.spin();
            //     },
            //     win ? 1000 : 100
            //   );
            // }
        });
    }
    payCredits(amount) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.credits < amount) {
                return Promise.reject("Not enough credits");
            }
            this.credits -= amount;
            this.interface.setCredits(this.credits);
            return amount;
        });
    }
    receiveCredits(amount) {
        return __awaiter(this, void 0, void 0, function* () {
            this.credits += amount;
            this.interface.setCredits(this.credits);
        });
    }
}
exports.Slot = Slot;
