"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const game_1 = require("./game");
require("./styles.scss");
window.onload = function () {
    const onLoaded = () => {
        var _a;
        const intro = (_a = document.getElementsByClassName("intro")) === null || _a === void 0 ? void 0 : _a[0];
        if (intro) {
            intro.classList.add("loaded");
        }
    };
    new game_1.Game(onLoaded);
};
