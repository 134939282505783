"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Assets = void 0;
const pixi_js_1 = require("pixi.js");
const game_1 = require("../game");
class Assets extends pixi_js_1.Loader {
    constructor() {
        super();
        this.baseUrl = "assets/";
        for (let index = 0; index < game_1.SLOT_ICON_COUNT; index++) {
            this.add((index + 1).toString(), `${index + 1}.png`);
        }
        this.add("bg", `bg.png`);
        this.add("logo", `logo.png`);
        this.add("reel", `reel.png`);
        this.add("button", `button.png`);
        this.add("button-pressed", `button-pressed.png`);
        this.onProgress.add((a) => {
            // todo: progress bar
        });
        this.onError.add((a) => {
            // todo: error handling
        });
        this.onComplete.add((a) => {
            console.log("Assets loaded.");
        });
        this.load();
    }
}
exports.Assets = Assets;
